import React from 'react'
import Plaatjie from '@ubo/plaatjie'

import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const StyledImageBlock = styled(Plaatjie)`
  opacity: 0.5;
  z-index: 1;
  height: 100%;
`

const ImageFade = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgb(16, 11, 61);
  background: linear-gradient(
    90deg,
    rgba(16, 11, 61, 0.200717787114846) 0%,
    rgba(16, 11, 61, 0.5164040616246498) 80%,
    rgba(16, 11, 61, 1) 95%
  );
`

const Content = styled(ParseContent)`
  color: white;
  padding: 40px 100px 40px 0;

  h1,
  h2,
  h4,
  h5,
  h6 {
    font-size: ${(props) => props.theme.font.size['80']};
    line-height: ${(props) => props.theme.font.size[70]};
    opacity: 0.26;
    padding-bottom: 10px;

    @media (max-width: 1200px) {
      font-size: ${(props) => props.theme.font.size['42']};
      line-height: ${(props) => props.theme.font.size['42']};
    }

    @media (max-width: 576px) {
      font-size: ${(props) => props.theme.font.size[32]};
    }
  }

  & h3 {
    padding-bottom: 20px;
    font-size: 60px;
    line-height: 60px;
    opacity: 0.26;

    @media (max-width: 1200px) {
      font-size: 40px;
      line-height: 40px;
    }

    @media (max-width: 576px) {
      font-size: 30px;
    }
  }

  a {
    color: ${(props) => props.theme.color.text.contrast} !important;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    padding-left: 3rem;
    padding-right: 5rem;
    padding-top: 2rem;

    @media (max-width: 992px) {
      padding-left: unset;
      padding-right: unset;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 40px 0 40px 0;
  }

  @media screen and (max-width: 576px) {
    padding: 10px 0 10px 0;
  }
`

const SectionWrapper = styled.section`
  background: rgb(16, 11, 61);
`

interface TextWithBackgroundImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithBackgroundImage
}

const TextWithBackgroundImage: React.FC<TextWithBackgroundImageProps> = ({
  fields,
}) => {
  const order: string = fields.view![0] === 'L' ? 'start' : 'end'
  return (
    <SectionWrapper className="container-fluid px-0">
      <div className={`row justify-content-${order}`}>
        <div className="col-lg-6 col-12 p-lg-0">
          <ImageContainer>
            <ImageFade />
            <StyledImageBlock image={fields?.image} alt="" />
          </ImageContainer>
        </div>
        <div className="col-lg-6 col-12 p-lg-0 p-4 d-flex align-items-center">
          <Content content={fields.description} />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default TextWithBackgroundImage
